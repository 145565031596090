import { Injectable } from '@angular/core';
import { CigarListEnum } from '@shared/models/cigar-log.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmitterService {
  detailsWhenLineStatus: Observable<boolean>;
  detailsWhenLinenData: Subject<boolean>;

  humidorDetailsScreenStatus: Observable<boolean>;
  humidorDetailsScreenData: Subject<boolean>;

  clearAllNotificationsStatus: Observable<boolean>;
  clearAllNotificationsData: Subject<boolean>;

  gatewayResponseStatus: Observable<boolean>;
  gatewayResponseData: Subject<boolean>;

  cigarListUpdatedData = new BehaviorSubject<CigarListEnum | ''>('');

  constructor() {
    this.humidorDetailsScreenData = new Subject<boolean>();
    this.humidorDetailsScreenStatus =
      this.humidorDetailsScreenData.asObservable();

    this.clearAllNotificationsData = new Subject<boolean>();
    this.clearAllNotificationsStatus =
      this.clearAllNotificationsData.asObservable();

    this.gatewayResponseData = new Subject<boolean>();
    this.gatewayResponseStatus = this.gatewayResponseData.asObservable();
  }

  closeHumidorDetailsScreen() {
    this.humidorDetailsScreenData.next(true);
  }

  clearAllNotifications() {
    this.clearAllNotificationsData.next(true);
  }

  gatewayResponseReceived() {
    this.gatewayResponseData.next(true);
  }

  cigarListRefreshed(listName: CigarListEnum) {
    this.cigarListUpdatedData.next(listName);
  }
}
